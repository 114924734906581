<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <header>
          <h2>Jobs</h2>
        </header>
        <b-tabs content-class="">
          <b-tab title="Jobs" class="p-3">
            <AddJob />
            <ViewJobs />
          </b-tab>
          <b-tab title="Department" class="p-3">
            <AddDept />
            <ViewDepts />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import AddJob from "../components/AddJob.vue";
import AddDept from "../components/AddDept.vue";
import ViewJobs from "../components/ViewJobs.vue";
import ViewDepts from "../components/ViewDepts.vue";

export default {
  name: "Jobs",
  components: {
    SideNav,
    Nav,
    AddJob,
    AddDept,
    ViewJobs,
    ViewDepts,
  },
  methods: {},
};
</script>

<style scoped>
</style>