<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <header>
          <h2>Faq</h2>
        </header>
        <div>
          <b-tabs content-class="bg-white h-100">
            <b-tab title="Add FAQ">
              <form @submit.prevent="saveForm" class="col-md-8">
                <!--<small :style="{ color: 'red' }">{{}}</small>-->
                <div class="form-group">
                  <label>Category</label>
                  <input
                    required
                    v-model="form.category"
                    class="form-control bg-light"
                    type="search"
                    list="data"
                  />
                  <datalist id="data">
                    <option
                      v-for="category in faqCategories"
                      :key="category"
                      :value="category"
                    >
                      {{ category }}
                    </option>
                  </datalist>
                </div>
                <div class="form-group">
                  <label>Question</label>
                  <input
                    required
                    v-model="form.question"
                    type="text"
                    class="form-control bg-light"
                  />
                </div>
                <div class="form-group">
                  <label>Answer</label>
                  <div class="example bg-white border-bottom">
                    <quill-editor
                      class="editor bg-light"
                      ref="myQuillEditor"
                      v-model="form.answer"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  ref="submit"
                  class="btn btn-primary float-right"
                >
                  Save
                </button>
              </form>
            </b-tab>
            <b-tab title="View FAQ" class="bg-light" active>
              <div class="d-flex flex-row p-2">
                <div class="d-flex align-items-center paginate mr-5">
                  <button class="btn btn-secondary btn-sm" @click="leftClick">
                    <ArrowLeftThick />
                  </button>
                  <input type="text" class="form-control" v-model="page" />
                  <button class="btn btn-secondary btn-sm" @click="rightClick">
                    <ArrowRightThick />
                  </button>
                </div>
                <div>
                  <select
                    v-model="selectedCategory"
                    @change="select"
                    class="custom-select"
                  >
                    <option disabled selected value="">Sort by Category</option>
                    <option
                      v-for="category in faqCategories"
                      :key="category"
                      :value="category"
                    >
                      {{ category }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row bg-white p-2 my-2 item-row item-head">
                <div class="col-md-3 d-flex align-items-center">
                  <p>Category</p>
                </div>
                <div class="col-md-3 d-flex align-items-center">
                  <p>Question</p>
                </div>
                <div class="col-md-5 d-flex align-items-center">
                  <p>Answer</p>
                </div>
                <div class="col-md-1 d-flex align-items-center">
                  <p>Action</p>
                </div>
              </div>

              <div
                v-for="faq in allFaqs"
                :key="faq._id"
                class="row bg-white p-2 my-2 item-row item-row"
              >
                <div class="col-md-3 d-flex align-items-center">
                  <p>{{ faq.category }}</p>
                </div>
                <div class="col-md-3 d-flex align-items-center">
                  <p>{{ faq.question }}</p>
                </div>
                <div class="col-md-5 d-flex align-items-center">
                  <p v-html="faq.answer"></p>
                </div>
                <div class="col-md-1 d-flex align-items-center">
                  <button
                    class="btn btn-sm btn-outline-danger"
                    @click="deleteFaq(faq._id)"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import ArrowRightThick from "vue-material-design-icons/ArrowRightThick.vue";
import ArrowLeftThick from "vue-material-design-icons/ArrowLeftThick.vue";
// import Bin from 'vue-material-design-icons/Bin.vue';
import { quillEditor } from "vue-quill-editor";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Faq",
  components: {
    SideNav,
    Nav,
    quillEditor,
    ArrowRightThick,
    ArrowLeftThick,
  },
  data() {
    return {
      form: {
        category: "",
        question: "",
        answer: "",
      },
      selectedCategory: "",
      page: 1,
      limit: process.env.VUE_APP_PAGE_LIMIT,
      categories: Object,
    };
  },
  methods: {
    ...mapActions(["saveFaq", "getFaqs", "delFaq"]),
    async saveForm() {
      const formdata = new FormData();
      formdata.append("category", this.form.category);
      formdata.append("question", this.form.question);
      formdata.append("answer", this.form.answer);
      const status = await this.saveFaq({ formdata });
      if (status) this.resetForm();
    },
    resetForm() {
      this.form.category = "";
      this.form.question = "";
      this.form.answer = "";
    },
    rightClick() {
      this.page++;
      this.getFaqs({
        category: this.selectedCategory,
        thePage: this.page,
        theLimit: this.limit,
        isLoading: true,
      });
    },
    leftClick() {
      if (this.page !== 1) this.page--;
      this.getFaqs({
        category: this.selectedCategory,
        thePage: this.page,
        theLimit: this.limit,
        isLoading: true,
      });
    },
    select() {
      this.page = 1;
      this.getFaqs({
        category: this.selectedCategory,
        thePage: this.page,
        theLimit: this.limit,
      });
    },
    deleteFaq(id) {
      this.delFaq({ id });
    },
  },
  computed: mapGetters(["allFaqs", "faqCategories"]),
  created() {
    this.getFaqs({
      thePage: this.page,
      theLimit: this.limit,
      isLoading: true,
    });
  },
};
</script>

<style lang="scss" scoped>
form {
  height: 100vh;
}
.editor {
  height: 15rem;
  overflow: hidden;
}
</style>