<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        ref="main"
        class="
          main
          col-12 col-md-9 col-xl-10
          py-md-3
          p-md-5
          bd-content
          bg-light
          rounded
          overflow-auto
        "
      >
        <div class="row justify-content-between">
          <div>
            <div class="d-flex justify-content-between">
              <header class="p-2 d-flex align-items-end">
                <h2>Reports</h2>
              </header>
            </div>
          </div>
          <div class="d-flex flex-column flex-lg-row query">
            <div class="p-2 d-flex align-items-end">
              <select v-model="option" @change="search" class="form-control">
                <option value="">Select Category</option>
                <option
                  v-for="option in reportOptions"
                  :key="option.text"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
            <div class="p-2 d-flex align-items-end">
              <div class="px-2 d-flex">
                <div class="mr-2">
                  <label><small>From:</small></label>
                  <input
                    @input="search"
                    v-model="date"
                    type="date"
                    class="form-control"
                  />
                </div>

                <div>
                  <label><small>To:</small></label>
                  <input
                    @input="search"
                    v-model="endDate"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="p-2 d-flex align-items-end paginate">
              <button class="btn btn-secondary btn-sm" @click="leftClick">
                <ArrowLeftThick />
              </button>
              <input type="text" class="form-control" v-model="page" />
              <button class="btn btn-secondary btn-sm" @click="rightClick">
                <ArrowRightThick />
              </button>
            </div>
          </div>
        </div>

        <div class="row bg-white p-3 my-2 item-row item-head">
          <div class="col-md-2 d-flex align-items-center">
            <p>Fullname</p>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <p>Category</p>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <p>Content</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Date</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>Action</p>
          </div>
        </div>

        <div
          v-for="report in allReports"
          :key="report._id"
          class="row bg-white p-3 my-2 item-row"
        >
          <div class="col-md-2 d-flex align-items-center">
            <router-link
              :to="{
                name: 'UserPayment',
                params: { id: report.createdBy._id },
              }"
              >{{
                report.createdBy.name.first + " " + report.createdBy.name.last
              }}</router-link
            >
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <ul>
              <template v-for="category in Object.keys(report)">
                <template v-if="report[category] === true">
                  <li :key="category">
                    {{ reportOptions.find((el) => el.value === category).text }}
                  </li>
                </template>
              </template>
            </ul>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <p>{{ report.description || "..." }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ report.createdAt.split("T")[0] }}</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <a
              :href="`${app}/explore/${report.postId}`"
              target="blank"
              class="btn btn-primary"
              >View</a
            >
          </div>
        </div>

        <div class="p-2 d-flex align-items-end paginate my-4">
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              leftClick();
            "
          >
            <ArrowLeftThick />
          </button>
          <input type="text" class="form-control" v-model="page" />
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              rightClick();
            "
          >
            <ArrowRightThick />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import ArrowRightThick from "vue-material-design-icons/ArrowRightThick.vue";
import ArrowLeftThick from "vue-material-design-icons/ArrowLeftThick.vue";
import { mapGetters, mapActions } from "vuex";
import { truncate } from "../utils";

export default {
  name: "Reports",
  components: {
    SideNav,
    Nav,
    ArrowRightThick,
    ArrowLeftThick,
  },
  data() {
    return {
      page: 1,
      limit: process.env.VUE_APP_PAGE_LIMIT,
      next: Object,
      previous: Object,
      option: "",
      date: "",
      endDate: "",
      app: process.env.VUE_APP_APP,
      reportOptions: [
        {
          text: "Sexual content",
          value: "sexual",
        },
        {
          text: "Violent or repulsive content",
          value: "violent",
        },
        {
          text: "Hateful or abusive content",
          value: "hateful",
        },
        {
          text: "Harmful or dangerous acts",
          value: "harmful",
        },
        {
          text: "Spam or misleading",
          value: "spam",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchReports"]),
    truncate,
    scrollTop(el) {
      this.$refs[el].scrollTo(0, 0);
    },
    search() {
      this.fetchReports({
        thePage: 1,
        theLimit: this.limit,
        option: this.option,
        date: this.date,
        endDate: this.endDate,
      });
    },
    searchQ() {
      this.fetchReports({
        thePage: 1,
        theLimit: this.limit,
        option: this.option,
        date: this.date,
        endDate: this.endDate,
        typing: true,
      });
    },
    rightClick() {
      this.page++;
      this.fetchReports({
        thePage: this.page,
        theLimit: this.limit,
        option: this.option,
        date: this.date,
        endDate: this.endDate,
        isloading: true,
      });
    },
    leftClick() {
      if (this.page !== 1) this.page--;
      this.fetchReports({
        thePage: this.page,
        theLimit: this.limit,
        option: this.option,
        date: this.date,
        endDate: this.endDate,
        isloading: true,
      });
    },
  },
  computed: mapGetters(["allReports", "nextReport", "previousReport"]),
  created() {
    this.fetchReports({
      thePage: this.page,
      theLimit: this.limit,
      option: this.option,
      date: this.date,
      endDate: this.endDate,
    });
  },
};
</script>

<style scoped>
</style>