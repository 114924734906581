<template>
  <div>
    <div class="row bg-white p-2 my-2 item-row item-head">
      <div class="col-md-3 d-flex align-items-center">
        <p>Job Title</p>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <p>Job Type</p>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <p>Department</p>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <p>Location</p>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <p>Experience</p>
      </div>
      <div class="col-md-1 d-flex align-items-center">
        <p>Action</p>
      </div>
    </div>

    <div
    v-for="job in allJobs"
    :key="job._id"
    class="row bg-white p-2 my-2 item-row item-row">
      <div class="col-md-3 d-flex align-items-center">
        <p>{{ job.jobTitle }}</p>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <p>{{ job.jobType }}</p>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <p>{{ job.department }}</p>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <p>{{ job.location }}</p>
      </div>
      <div class="col-md-2 d-flex align-items-center">
        <p>{{ job.experience }}</p>
      </div>
      <div class="col-md-1 d-flex align-items-center">
        <router-link v-b-tooltip.hover title="View Applications" class="btn btn-secondary btn-sm" :to="{ name: 'JobApplication', params: { id: job._id, job }}"><ArrowRightThick/></router-link>
        <!-- <b-button v-b-modal.view-job @click="showInModal(job)">View</b-button> -->
      </div>
    </div>

    <!-- <b-modal id="view-job" title="" ok-only>
      <p
      v-for="job in shownJob"
      :key="job._id">{{ job }}</p>
    </b-modal>
    -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ArrowRightThick from 'vue-material-design-icons/ArrowRightThick.vue';

export default {
  name: 'ViewJobs',
  components: {
    ArrowRightThick,
  },
  data() {
    return {
      shownJob: {},
    }
  },
   methods: {
    ...mapActions(['fetchJobs']),
    showInModal(job) {
      // alert(JSON.stringify(job));
      this.shownJob = job;
    }
   },
  computed: mapGetters(['allJobs']),
  created() {
    this.fetchJobs();
  }
}
</script>

<style>

</style>