<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <header>
          <h2>{{ job.jobTitle }}</h2>
        </header>
        <div class="mb-4">
          <table>
            <tr>
              <td><b>Location</b></td>
              <td>{{ job.location }}</td>
            </tr>
            <tr>
              <td><b>Vacancies</b></td>
              <td>{{ job.vacancies }}</td>
            </tr>
            <tr>
              <td><b>Experience</b></td>
              <td>{{ job.experience }}</td>
            </tr>
            <tr>
              <td><b>Salary</b></td>
              <td>{{ job.salaryFrom + "-" + job.salaryTo }}</td>
            </tr>
            <tr>
              <td><b>Job Type</b></td>
              <td>{{ job.jobType }}</td>
            </tr>
            <tr>
              <td><b>Status</b></td>
              <td>{{ job.status }}</td>
            </tr>
            <tr>
              <td><b>Expiry</b></td>
              <td>{{ job.expiredDate }}</td>
            </tr>
            <tr>
              <td><b>Applications</b></td>
              <td>{{ job.applications }}</td>
            </tr>
          </table>
        </div>

        <h3>Applications</h3>
        <div class="row bg-white p-2 my-2 item-row item-head">
          <div class="col-md-2 d-flex align-items-center">
            <p>Name</p>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <p>Email</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Phone</p>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <p>Message</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>CV</p>
          </div>
        </div>

        <div
          v-for="app in applications"
          :key="app._id"
          class="row bg-white p-2 my-2 item-row"
        >
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ app.name }}</p>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <p>{{ app.email }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ app.message }}</p>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <p>{{ app.phone }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <div v-if="app.hasOwnProperty('resume')">
              <a
                v-b-tooltip.hover
                title="Download CV"
                :href="`${env}/api/application/download/${encodeURIComponent(
                  app.resume.original
                )}`"
                :download="app.resume.original"
                >{{ app.resume.original }}</a
              >
            </div>
          </div>
        </div>

        <div class="text-danger border col-md-4 p-4 mt-5">
          <p><b>Warning</b> You can't undo this.</p>
          <button class="btn btn-danger" @click="deleteJob(job._id)">
            Delete Job
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";

export default {
  name: "SupportTickets",
  components: {
    SideNav,
    Nav,
  },
  props: {
    id: String,
    job: Object,
  },
  data() {
    return {
      applications: [],
      env: process.env.VUE_APP_API,
    };
  },
  methods: {
    async getAppl() {
      const loader = this.$loading.show();
      try {
        const response = await fetch(
          process.env.VUE_APP_API + `/api/application/getByJobId/${this.id}`
        );
        if (response.status < 400) {
          const { applications } = await response.json();
          // console.log(applications);
          this.applications = applications;
        }
      } catch (e) {
        this.$toast.open({
          message: e,
          type: "error",
        });
      } finally {
        loader.hide();
      }
    },
    async deleteJob(id) {
      const loader = this.$loading.show();
      try {
        const response = await fetch(
          process.env.VUE_APP_API + `/api/job/delete/${id}`
        );
        if (response.status < 400) {
          this.$router.push({ path: "/jobs" });
        }
      } catch (e) {
        this.$toast.open({
          message: e,
          type: "error",
        });
      } finally {
        loader.hide();
      }
    },
  },
  created() {
    this.getAppl();
  },
};
</script>

<style scoped>
.main {
  height: 100vh;
}
</style>