<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <header>
          <h2>Email</h2>
          <p>Send Bulk email to customers</p>
        </header>

        <div class="row">
          <div class="form-group col-md-8">
            <label><b>Subject:</b></label>
            <textarea
              required
              v-model="subject"
              type="text"
              class="form-control"
            ></textarea>
          </div>

          <div class="form-group col-md-4">
            <label><b>Receipient:</b></label>
            <select
              v-model="receipient"
              @change="searchAccount"
              class="form-control"
            >
              <option value="" selected>All</option>
              <option value="1">Free</option>
              <option value="2">Personal Pro</option>
              <option value="3">Business</option>
              <option value="4">Business Plus</option>
            </select>
          </div>
        </div>

        <div class="example bg-white border-bottom">
          <quill-editor class="editor" ref="myQuillEditor" v-model="content" />
        </div>

        <div>
          <label for="attachments" class="btn btn-light m-2"
            ><Paperclip /> Add attachment
            <b-badge variant="primary">{{ fileCount }}</b-badge></label
          >
          <input
            type="file"
            id="attachments"
            name="attachments"
            accept=""
            multiple
            class="d-none"
            @change="countFile"
          />
          <button
            class="btn btn-primary m-2"
            @click="sendMail()"
            ref="sendMail"
          >
            {{ btntext }}
          </button>
        </div>

        <hr v-if="files.length" />
        <div
          v-for="(file, index) in files"
          :key="index"
          class="d-flex justify-content-between mb-1 col-md-6"
        >
          <div>{{ file.name }}</div>
          <button
            class="btn btn-sm btn-outline-danger"
            @click="removeAttachment(index)"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import { quillEditor } from "vue-quill-editor";
import Paperclip from "vue-material-design-icons/Paperclip.vue";
// import { fetchAPI } from "../utils";

export default {
  name: "Email",
  components: {
    SideNav,
    Nav,
    quillEditor,
    Paperclip,
  },
  data() {
    return {
      btntext: "Send Mail",
      subject: "",
      content: "",
      fileCount: "",
      receipient: "",
      files: [],
    };
  },
  methods: {
    countFile(e) {
      console.log("count", e);
      const files = [];
      e.target.files.forEach((file) => {
        files.push(file);
      });
      this.files = [...this.files, ...files];
      this.fileCount = this.files.length;
    },
    removeAttachment(index) {
      const files = Array.from(this.files).filter((x, i) => i !== index);
      this.files = files;
      this.fileCount = this.files.length;
    },
    async sendMail() {
      try {
        const formData = new FormData();
        if (this.files.length) {
          this.files.forEach((file) => {
            formData.append("attachments", file, file.name);
          });
        }

        this.btntext = "Sending...";
        this.$refs.sendMail.disabled = true;
        formData.append("reciepient", this.receipient);
        formData.append("subject", this.subject);
        formData.append("content", this.content);

        const response = await fetch(
          process.env.VUE_APP_API + `/api/mail/bulk-mail`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.status < 400) {
          this.resetForm();
          this.$toast.open("Mails Sent");
        } else {
          this.$toast.open({
            message: "Server Unable to send mail",
            type: "error",
          });
        }
      } catch (e) {
        this.$toast.open({
          message: "Error: sending mail",
          type: "error",
        });
      } finally {
        this.btntext = "Send Mail";
        this.$refs.sendMail.disabled = false;
      }
    },
    resetForm() {
      this.subject = "";
      this.content = "";
      this.receipient = "";
      this.files = [];
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 25rem;
    overflow: hidden;
  }
}

.email-html {
  white-space: break-spaces;
}
</style>