<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        ref="main"
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <div class="row justify-content-between">
          <header>
            <h2>Companies</h2>
            <p>Companies on this platform</p>
            <p><Domain /> {{ companyCount }}</p>
          </header>
          <div class="d-flex flex-column flex-lg-row query">
            <div class="p-2 d-flex align-items-end">
              <input
                v-debounce:600ms="search"
                v-model="company_name"
                type="text"
                class="form-control"
                placeholder="Search..."
              />
            </div>
            <div class="p-2 d-flex align-items-end">
              <div class="px-2 d-flex">
                <div class="mr-2">
                  <label><small>From:</small></label>
                  <input
                    @input="searchByDate"
                    v-model="date"
                    type="date"
                    class="form-control"
                  />
                </div>

                <div>
                  <label><small>To:</small></label>
                  <input
                    @input="searchByDate"
                    v-model="endDate"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="p-2 d-flex align-items-end paginate">
              <button class="btn btn-secondary btn-sm" @click="leftClick">
                <ArrowLeftThick />
              </button>
              <input type="text" class="form-control" v-model="page" />
              <button class="btn btn-secondary btn-sm" @click="rightClick">
                <ArrowRightThick />
              </button>
            </div>
          </div>
        </div>

        <div class="row bg-white p-2 my-2 item-row item-head">
          <div class="col-md-3 d-flex align-items-center">
            <p>Name</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Address</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Country</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Phone</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Date Created</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>Action</p>
          </div>
        </div>

        <div
          v-for="company in allCompanies"
          :key="company._id"
          @click="view('view' + company._id)"
          class="row bg-white p-2 my-2 item-row has-cursor"
        >
          <div class="col-md-3 d-flex align-items-center">
            <p>{{ company.name || "..." }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ company.address || "..." }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ company.country || "..." }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ company.phone || "..." }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ company.createdAt.split("T")[0] }}</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <router-link
              v-b-tooltip.hover
              title="View Company"
              :ref="'view' + company._id"
              class="btn btn-secondary btn-sm"
              :to="{ name: 'ViewCompany', params: { id: company._id } }"
              ><ArrowRightThick
            /></router-link>
          </div>
        </div>

        <div class="p-2 d-flex align-items-end paginate my-4">
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              leftClick();
            "
          >
            <ArrowLeftThick />
          </button>
          <input type="text" class="form-control" v-model="page" />
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              rightClick();
            "
          >
            <ArrowRightThick />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import Domain from "vue-material-design-icons/Domain.vue";
import ArrowRightThick from "vue-material-design-icons/ArrowRightThick.vue";
import ArrowLeftThick from "vue-material-design-icons/ArrowLeftThick.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Companies",
  components: {
    SideNav,
    Nav,
    ArrowRightThick,
    ArrowLeftThick,
    Domain,
  },
  data() {
    return {
      page: 1,
      limit: process.env.VUE_APP_PAGE_LIMIT,
      next: Object,
      previous: Object,
      company_name: "",
      date: "",
      endDate: "",
    };
  },
  methods: {
    ...mapActions(["fetchCompanies", "searchCompanies"]),
    scrollTop(el) {
      this.$refs[el].scrollTo(0, 0);
    },
    view(theref) {
      this.$refs[theref][0].$el.click();
    },
    goBack() {
      this.$router.go(-1);
    },
    search() {
      this.page = 1;
      this.searchCompanies({
        name: this.company_name,
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        endDate: this.endDate,
      });
    },
    searchByDate() {
      this.page = 1;
      this.searchCompanies({
        name: this.company_name,
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        isLoading: true,
        endDate: this.endDate,
      });
    },
    rightClick() {
      this.page++;
      this.searchCompanies({
        name: this.company_name,
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        isLoading: true,
        endDate: this.endDate,
      });
    },
    leftClick() {
      if (this.page !== 1) this.page--;
      this.searchCompanies({
        name: this.company_name,
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        isLoading: true,
        endDate: this.endDate,
      });
    },
  },
  computed: mapGetters([
    "allCompanies",
    "nextCompanies",
    "previousCompanies",
    "companyCount",
  ]),
  created() {
    this.fetchCompanies({
      thePage: this.page,
      theLimit: this.limit,
    });
  },
};
</script>

<style scoped>
</style>