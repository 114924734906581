<template>
  <div>
    <b-button v-b-modal.add-dept>Add Dept</b-button>
    <b-modal id="add-dept" title="Add Department" :hide-footer="true">
      <form @submit.prevent="submit">
      <small :style="{color: 'red'}">{{ }}</small>
      <div class="form-group">
        <label>Department</label>
        <input 
        required
        v-model="form.department"
        type="text" class="form-control bg-light">
      </div>
      <button type="submit" ref="submit" class="btn btn-primary">Save</button>
    </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AddDept',
  data() {
    return {
      form: {
        department: '',
      }
    }
  },
  methods: {
    ...mapActions(['addDepts']),
    async submit() {
     const status = await this.addDepts({
        deptname: this.form.department
      });
      if (status) {
        this.form.department = '';
        this.$bvModal.hide('add-dept');
      }
    }
   },
}
</script>

<style>

</style>