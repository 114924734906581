<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        ref="main"
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <div class="row justify-content-between">
          <header>
            <h2>Payments</h2>
          </header>
          <div class="d-flex flex-column flex-lg-row query">
            <div class="p-2 d-flex align-items-end">
              <input
                v-debounce:700ms="typing"
                v-model="q"
                type="text"
                class="form-control w-100"
                placeholder="Search reciept ID..."
              />
            </div>
            <div class="p-2 d-flex align-items-end">
              <div class="px-2 d-flex">
                <div class="mr-2">
                  <label><small>From:</small></label>
                  <input
                    v-debounce:700ms="getPaymentsByDate"
                    v-model="date"
                    type="date"
                    class="form-control"
                  />
                </div>

                <div>
                  <label><small>To:</small></label>
                  <input
                    v-debounce:700ms="getPaymentsByDate"
                    v-model="endDate"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="p-2 d-flex align-items-end paginate">
              <button class="btn btn-secondary btn-sm" @click="leftClick">
                <ArrowLeftThick />
              </button>
              <input type="text" class="form-control" v-model="page" />
              <button class="btn btn-secondary btn-sm" @click="rightClick">
                <ArrowRightThick />
              </button>
            </div>
          </div>
        </div>

        <div class="row bg-white p-3 my-2 item-row item-head">
          <div class="col-md-2 d-flex align-items-center">
            <p>Email</p>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <p>ID</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Payment Date</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Plan</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Amount</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>Action</p>
          </div>
        </div>

        <div
          v-for="payment in allPayments"
          :key="payment._id"
          @click="view('view' + payment._id)"
          class="row bg-white p-3 my-2 item-row item-row has-cursor"
        >
          <div class="col-md-2 d-flex align-items-center word-break">
            <p>{{ payment.user_id != null ? payment.user_id.email : "..." }}</p>
          </div>
          <div class="col-md-3 d-flex align-items-center word-break">
            <p>{{ payment.flw_ref }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ payment.payment_date.split("T")[0] }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ payment.plan }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center text-success">
            <p>
              {{
                payment.currency +
                " " +
                Math.floor(payment.amount).toLocaleString()
              }}
            </p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <router-link
              v-b-tooltip.hover
              title="View"
              v-if="payment.user_id"
              :ref="'view' + payment._id"
              :to="{ name: 'UserPayment', params: { id: payment.user_id._id } }"
              class="btn btn-light"
              type="button"
              >View</router-link
            >
          </div>
        </div>

        <div class="p-2 d-flex align-items-end paginate my-4">
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              leftClick();
            "
          >
            <ArrowLeftThick />
          </button>
          <input type="text" class="form-control" v-model="page" />
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              rightClick();
            "
          >
            <ArrowRightThick />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import ArrowRightThick from "vue-material-design-icons/ArrowRightThick.vue";
import ArrowLeftThick from "vue-material-design-icons/ArrowLeftThick.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SupportTickets",
  components: {
    SideNav,
    Nav,
    ArrowRightThick,
    ArrowLeftThick,
  },
  data() {
    return {
      page: 1,
      date: "",
      endDate: "",
      q: "",
      limit: 5,
      next: Object,
      previous: Object,
    };
  },
  methods: {
    ...mapActions(["fetchPayments"]),
    scrollTop(el) {
      this.$refs[el].scrollTo(0, 0);
    },
    view(theref) {
      this.$refs[theref][0].$el.click();
    },
    rightClick() {
      this.page++;
      this.fetchPayments({
        q: this.q,
        date: this.date,
        endDate: this.endDate,
        thePage: this.page,
        theLimit: this.limit,
        isLoading: true,
      });
    },
    leftClick() {
      if (this.page !== 1) this.page--;
      this.fetchPayments({
        q: this.q,
        date: this.date,
        endDate: this.endDate,
        thePage: this.page,
        theLimit: this.limit,
        isLoading: true,
      });
    },
    typing() {
      this.page = 1;
      this.fetchPayments({
        q: this.q,
        date: this.date,
        endDate: this.endDate,
        thePage: this.page,
        theLimit: this.limit,
      });
    },
    getPaymentsByDate() {
      this.page = 1;
      this.fetchPayments({
        q: this.q,
        date: this.date,
        endDate: this.endDate,
        thePage: this.page,
        theLimit: this.limit,
      });
    },
  },
  computed: mapGetters(["allPayments", "nextPayment", "previousPayment"]),
  created() {
    this.fetchPayments({
      q: this.q,
      date: this.date,
      endDate: this.endDate,
      thePage: this.page,
      theLimit: this.limit,
      isLoading: true,
    });
  },
};
</script>

<style scoped>
</style>