<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <GoBack />
        <div class="d-flex justify-content-between">
          <div class="mb-4">
            <h2 class="d-inline">
              {{ pUser.name.first + " " + pUser.name.last }}
            </h2>
            <p class="m-0">
              {{ pUser.email }},
              {{ pUser.country }}
            </p>
            <p class="m-0">
              <b>Current Plan:</b>
              {{ plans[pUser.current_plan] }}
            </p>
            <p class="m-0">
              <b>Account Type:</b>
              {{ pUser.account_type == 1 ? "personal" : "Buisiness" }}
            </p>
            <p class="m-0">
              <b>Joined:</b>
              {{
                format(new Date(pUser.createdAt), "do MMMM, yyyy HH:mm aaaa")
              }}
            </p>

            <b-modal
              id="send-mail"
              size="xl"
              title="Send Mail"
              :hide-footer="true"
              no-close-on-backdrop
              scrollable
            >
              <SendUserMail v-bind:pUser="pUser" />
            </b-modal>
          </div>
          <div class="col-4">
            <div>
              <h5>
                <small
                  ><b>Usage:</b>
                  {{
                    total_usage === "Unlimited" || total_usage === Infinity
                      ? total_usage
                      : prettyBytes(total_usage)
                  }}
                  of
                  {{
                    total_allocated_space === "Unlimited" ||
                    total_allocated_space === Infinity
                      ? total_allocated_space
                      : prettyBytes(total_allocated_space)
                  }}</small
                >
              </h5>
              <b-progress
                :value="total_usage"
                :max="total_allocated_space"
                :striped="true"
                class="mb-3 border"
              ></b-progress>

              <b-button v-b-modal.send-mail>Send Mail</b-button>
            </div>
          </div>
        </div>

        <div class="d-flex stat-container justify-content-between">
          <div class="border d-flex stat">
            <div class="col-10">
              <p>Folders</p>
              <b>{{ userStat.folderCount.toLocaleString() }}</b>
            </div>
            <span class="stat-icon" style="background-color: #dbf9e0">
              <Folder class="folder-icon" />
            </span>
          </div>

          <div class="border d-flex stat">
            <div class="col-10">
              <p>Files</p>
              <b>{{ userStat.filesCount.toLocaleString() }}</b>
            </div>
            <span class="stat-icon" style="background-color: #d3e4fb">
              <File class="file-icon" />
            </span>
          </div>

          <div class="border d-flex stat">
            <div class="col-10">
              <p>Projects</p>
              <b>{{ userStat.taskCount.toLocaleString() }}</b>
            </div>
            <span class="stat-icon" style="background-color: #fcd6ec">
              <FolderOpen class="project-icon" />
            </span>
          </div>

          <div class="border d-flex stat mb-3" style="margin-right: 0">
            <div class="col-10">
              <p>Tasks</p>
              <b>{{ userStat.projectCount.toLocaleString() }}</b>
            </div>
            <span class="stat-icon" style="background-color: #fcebd6">
              <CalendarCheck class="task-icon" />
            </span>
          </div>
        </div>

        <div class="row my-4 ml-2">
          <div class="chart-parent bg-white col-md-10">
            <canvas ref="ctx"></canvas>
          </div>
          <div class="col mr-2">
            <select @change="setDays" v-model="days" class="custom-select">
              <option value="7">Last 7 Days</option>
              <option value="14">Last 14 Days</option>
              <option value="30">Last 30 Days</option>
            </select>

            <div class="p-2 mt-4 bg-white my-shadow">
              <p>
                Referrals: <b>{{ totalReferral.toLocaleString() }}</b>
              </p>
              <hr />
              <h6>Rewards</h6>
              <p class="text-success">
                &#x24; <b>{{ totalReferralRewardUSD.toLocaleString() }}</b>
              </p>
              <p class="text-success">
                &#x20a6; <b>{{ totalReferralRewardNGN.toLocaleString() }}</b>
              </p>
            </div>
          </div>
        </div>

        <div v-if="payment" class="bg-white border mt-3">
          <table class="table w-auto">
            <thead>
              <tr class="d-none">
                <th scope="col">#</th>
                <th scope="col">First</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Tran ref</th>
                <td>{{ payment.tran_ref }}</td>
              </tr>
              <tr>
                <th scope="row">Flw ref</th>
                <td>{{ payment.flw_ref }}</td>
              </tr>
              <tr>
                <th scope="row">Plan</th>
                <td>{{ payment.plan }}</td>
              </tr>
              <tr>
                <th scope="row">Duration Type</th>
                <td>{{ payment.duration_type }}</td>
              </tr>
              <tr>
                <th scope="row">Amount</th>
                <td>
                  {{
                    payment.currency +
                    " " +
                    Math.floor(payment.amount).toLocaleString()
                  }}
                </td>
              </tr>
              <tr>
                <th scope="row">Payment Date</th>
                <td>{{ payment.payment_date.split("T")[0] }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="!payment" class="bg-white border p-4 text-center">
          <p class="m-0 p-0">No Payment Found.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import SendUserMail from "../components/SendUserMail";
import Chart from "chart.js";
import GoBack from "../components/GoBack";
import Folder from "vue-material-design-icons/Folder.vue";
import FolderOpen from "vue-material-design-icons/FolderOpen.vue";
import File from "vue-material-design-icons/File.vue";
import CalendarCheck from "vue-material-design-icons/CalendarCheck.vue";
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";

import prettyBytes from "pretty-bytes";

export default {
  name: "UserPayment",
  components: {
    SideNav,
    Nav,
    GoBack,
    Folder,
    File,
    CalendarCheck,
    FolderOpen,
    SendUserMail,
  },
  props: {
    id: String,
  },
  data() {
    return {
      total_usage: 0,
      total_allocated_space: 0,
      total_remaining: 0,
      label: [],
      filesData: [],
      folderData: [],
      projectData: [],
      userData: [],
      teamData: [],
      totalCustomers: 0,
      totalFiles: 0,
      totalTeams: 0,
      totalProjects: 0,
      totalReferralRewardUSD: 0,
      totalReferralRewardNGN: 0,
      totalReferral: 0,
      days: 7,
      chart: null,
      plans: ["", "Free", "Personal Pro", "Business", "Business Plus"],
    };
  },
  methods: {
    ...mapActions(["fetchPaymentsForUser", "fetchUserStats"]),
    prettyBytes,
    format,
    async setDays() {
      await this.getLastDays(parseInt(this.days));
      this.chart.data.labels = [...this.label.reverse()];
      this.chart.data.datasets = [
        {
          label: "New Folders",
          borderColor: "#4de266",
          backgroundColor: "#dbf9e0",
          data: [...this.folderData.reverse()],
          fill: false,
        },
        {
          label: "Files Uploaded",
          fill: false,
          borderColor: "#3e8aed",
          backgroundColor: "#d3e4fb",
          data: [...this.filesData.reverse()],
        },
        {
          label: "New Projects",
          fill: false,
          borderColor: "#d11aa0",
          backgroundColor: "#fcd6ec",
          data: [...this.projectData.reverse()],
        },
        {
          label: "New Task",
          fill: false,
          borderColor: "#ee9c30",
          backgroundColor: "#fcebd6",
          data: [...this.taskData.reverse()],
        },
      ];
      this.chart.update();
    },
    async getLastDays(days) {
      // const loader = this.$loading.show();
      try {
        const response = await fetch(
          process.env.VUE_APP_API +
            `/api/stats/last-days/${days}?user_id=${this.$props.id}`
        );
        const { data } = await response.json();

        this.filesData = data.filesData;
        this.folderData = data.folderData;
        this.projectData = data.projectData;
        this.userData = data.userData;
        this.taskData = data.taskData;
        this.label = data.label;
        this.totalCustomers = data.userCount;
        this.totalFiles = data.filesCount;
        this.totalTeams = data.teamCount;
        this.totalProjects = data.projectCount;
        this.totalReferralRewardNGN = data.totalReferralRewardNGN.length
          ? data.totalReferralRewardNGN[0].sum
          : 0;
        this.totalReferralRewardUSD = data.totalReferralRewardUSD.length
          ? data.totalReferralRewardUSD[0].sum
          : 0;
      } catch (e) {
        this.$toast.open({
          message: "Unable to load Chart Data",
          type: "error",
        });
      } finally {
        // loader.hide();
      }
    },
    renderChart() {
      const ctx = this.$refs.ctx;
      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: [...this.label.reverse()],
          datasets: [
            {
              label: "New Folders",
              borderColor: "#4de266",
              backgroundColor: "#dbf9e0",
              data: [...this.folderData.reverse()],
              fill: false,
            },
            {
              label: "Files Uploaded",
              fill: false,
              borderColor: "#3e8aed",
              backgroundColor: "#d3e4fb",
              data: [...this.filesData.reverse()],
            },
            {
              label: "New Projects",
              fill: false,
              borderColor: "#d11aa0",
              backgroundColor: "#fcd6ec",
              data: [...this.projectData.reverse()],
            },
            {
              label: "New Task",
              fill: false,
              borderColor: "#ee9c30",
              backgroundColor: "#fcebd6",
              data: [...this.taskData.reverse()],
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: "Activities",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            x: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
            },
            y: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Value",
              },
            },
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
  },
  computed: mapGetters(["pUser", "payment", "userStat"]),
  async created() {},
  async mounted() {
    const loader = this.$loading.show();
    try {
      const fetchUserStats = await this.fetchUserStats({
        user_id: this.$props.id,
      });

      const fetchPaymentsForUser = await this.fetchPaymentsForUser({
        user_id: this.$props.id,
      });

      const fetchstat = await this.getLastDays(this.days);

      await Promise.all([fetchUserStats, fetchPaymentsForUser, fetchstat]);
      if (process.env.VUE_APP_ENV === "production") {
        const response = await fetch(process.env.VUE_APP_STORAGE_STAT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: this.$props.id }),
        });
        if (response.status < 400) {
          const data = await response.json();
          this.total_usage = data.total_usage ? data.total_usage : 0;
          this.total_allocated_space =
            data.total_allocated_space !== "Unlimited"
              ? data.total_allocated_space
              : Infinity;
          this.total_remaining =
            data.total_remaining !== "Unlimited"
              ? data.total_remaining
              : Infinity;
        } else {
          this.$toast.open({
            message: "Unable to get Storage stats",
            type: "error",
          });
        }
      }

      this.renderChart();
    } catch (e) {
      /*this.$toast.open({
        message: "Error loading Page",
        type: "error",
      });
      */
      console.log(e);
    } finally {
      loader.hide();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>

<style lang="scss" scoped>
</style>