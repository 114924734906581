<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <GoBack />
        <header>
          <h2>{{ this.$props.name }} Statistics</h2>
          <p>Keep Track of a company Statistics on the platform</p>
        </header>

        <div class="d-flex stat-container justify-content-between">
          <div class="border d-flex stat">
            <div class="col-10">
              <p>Users</p>
              <b>{{ totalCustomers }}</b>
            </div>
            <span class="stat-icon" style="background-color: #dbf9e0">
              <Account class="folder-icon" />
            </span>
          </div>

          <div class="border d-flex stat">
            <div class="col-10">
              <p>Folders</p>
              <b>{{ totalFolders }}</b>
            </div>
            <span class="stat-icon" style="background-color: #d3e4fb">
              <Folder class="file-icon" />
            </span>
          </div>

          <div class="border d-flex stat">
            <div class="col-10">
              <p>Projects</p>
              <b>{{ totalProjects }}</b>
            </div>
            <span class="stat-icon" style="background-color: #fcd6ec">
              <FolderOpen class="project-icon" />
            </span>
          </div>

          <div class="border d-flex stat">
            <div class="col-10">
              <p>Tasks</p>
              <b>{{ totalTasks }}</b>
            </div>
            <span class="stat-icon" style="background-color: #fcebd6">
              <CalendarCheck class="task-icon" />
            </span>
          </div>
        </div>

        <div class="row my-3">
          <div class="chart-parent bg-white col-md-10">
            <canvas ref="ctx"></canvas>
          </div>
          <div class="col mr-2">
            <select @change="setDays" v-model="days" class="custom-select">
              <option value="7">Last 7 Days</option>
              <option value="14">Last 14 Days</option>
              <option value="30">Last 30 Days</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import GoBack from "../components/GoBack";

import Account from "vue-material-design-icons/Account.vue";
import FolderOpen from "vue-material-design-icons/FolderOpen.vue";
import Folder from "vue-material-design-icons/Folder.vue";
// import File from "vue-material-design-icons/File.vue";
import CalendarCheck from "vue-material-design-icons/CalendarCheck.vue";
// import { mapActions } from "vuex";

export default {
  name: "SupportTickets",
  components: {
    SideNav,
    Nav,
    GoBack,
    Account,
    FolderOpen,
    Folder,
    // File,
    CalendarCheck,
  },
  props: {
    id: String,
    name: String,
  },
  data() {
    return {
      label: [],
      filesData: [],
      folderData: [],
      projectData: [],
      userData: [],
      teamData: [],
      totalCustomers: 0,
      totalFiles: 0,
      totalTeams: 0,
      totalProjects: 0,
      totalTasks: 0,
      totalFolders: 0,
      days: 7,
      chart: null,
    };
  },
  methods: {
    async setDays() {
      await this.getLastDays(parseInt(this.days));
      this.chart.data.labels = [...this.label.reverse()];
      this.chart.data.datasets = [
        {
          label: "Folders created",
          fill: false,
          borderColor: "#3e8aed",
          backgroundColor: "#d3e4fb",
          data: [...this.folderData.reverse()],
        },
        {
          label: "Total Projects",
          fill: false,
          borderColor: "#d11aa0",
          backgroundColor: "#fcd6ec",
          data: [...this.projectData.reverse()],
        },
        {
          label: "Total Task",
          fill: false,
          borderColor: "#ee9c30",
          backgroundColor: "#fcebd6",
          data: [...this.teamData.reverse()],
        },
      ];
      this.chart.update();
    },
    async getLastDays(days) {
      const loader = this.$loading.show();
      try {
        const response = await fetch(
          process.env.VUE_APP_API +
            `/api/stats/last-days/${days}?company_id=${this.$props.id}`
        );
        const { data } = await response.json();

        this.filesData = data.filesData;
        this.folderData = data.folderData;
        this.projectData = data.projectData;
        this.userData = data.userData;
        this.teamData = data.teamData;
        this.label = data.label;
        this.totalCustomers = data.userCount;
        this.totalTasks = data.taskCount;
        this.totalTeams = data.teamCount;
        this.totalProjects = data.projectCount;
        this.totalFolders = data.folderCount;
      } catch (e) {
        this.$toast.open({
          message: "Unable to load Chart Data",
          type: "error",
        });
      } finally {
        loader.hide();
      }
    },
    renderChart() {
      const ctx = this.$refs.ctx;
      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: [...this.label.reverse()],
          datasets: [
            /*{
              label: "New Users",
              borderColor: "#4de266",
              backgroundColor: "#dbf9e0",
              data: [...this.userData.reverse()],
              fill: false,
            },
            */
            {
              label: "Folders created",
              fill: false,
              borderColor: "#3e8aed",
              backgroundColor: "#d3e4fb",
              data: [...this.folderData.reverse()],
            },
            {
              label: "Total Projects",
              fill: false,
              borderColor: "#d11aa0",
              backgroundColor: "#fcd6ec",
              data: [...this.projectData.reverse()],
            },
            {
              label: "Total Task",
              fill: false,
              borderColor: "#ee9c30",
              backgroundColor: "#fcebd6",
              data: [...this.teamData.reverse()],
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: "Activities",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            x: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
            },
            y: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Value",
              },
            },
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
      // console.log(this.chart);
    },
  },
  async mounted() {
    await this.getLastDays(this.days);
    /*function randomScalingFactor() {
      return Math.floor(Math.random() * 100) + 1;
    }
    */
    this.renderChart();
  },
};
</script>

<style scoped>
canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>