<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto mb-5"
        ref="main"
      >
        <div class="row justify-content-between">
          <header>
            <h2>Abuse</h2>
            <p>View Abuse reported by users</p>
          </header>
          <div class="d-flex flex-column flex-lg-row query">
            <div class="p-2 d-flex align-items-end">
              <input
                v-model="q"
                v-debounce:500ms="searchQ"
                type="text"
                class="form-control"
                placeholder="Search..."
              />
            </div>
            <div class="p-2 d-flex align-items-end">
              <div class="px-2 d-flex">
                <div class="mr-2">
                  <label><small>From:</small></label>
                  <input
                    @input="search"
                    v-model="date"
                    type="date"
                    class="form-control"
                  />
                </div>

                <div>
                  <label><small>To:</small></label>
                  <input
                    @input="search"
                    v-model="endDate"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="p-2 d-flex align-items-end paginate">
              <button class="btn btn-secondary btn-sm" @click="leftClick">
                <ArrowLeftThick />
              </button>
              <input type="text" class="form-control" v-model="page" />
              <button class="btn btn-secondary btn-sm" @click="rightClick">
                <ArrowRightThick />
              </button>
            </div>
          </div>
        </div>

        <div class="row bg-white p-2 my-2 item-row item-head">
          <div class="col-md-2 d-flex align-items-center">
            <p>Fullname</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Email</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Name of User/Agent/Staff</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Add. Info.</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Reason</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>File</p>
          </div>
        </div>

        <div
          v-for="abuse in allAbuses"
          :key="abuse._id"
          class="row bg-white p-2 my-2 item-row"
        >
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ abuse.fullname }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center word-break">
            <p>{{ abuse.email_address }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ abuse.name_of_user_agent_staff }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ abuse.additional_information }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ abuse.reason_of_violation }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center word-break">
            <div v-if="abuse.hasOwnProperty('file')">
              <a
                class="btn btn-sm btn-secondary"
                v-b-tooltip.hover
                :title="'Download' + abuse.file.name"
                :href="`${env}/api/download/abuse-file${abuse.file.path}/${abuse.file.name}`"
                :download="abuse.file.name"
                >Download</a
              >

              <button
                v-if="abuse.file.type.includes('image')"
                class="btn btn-sm btn-light ml-2"
                @click="
                  view(
                    `${env}/api/download/abuse-file${abuse.file.path}/${abuse.file.name}`,
                    'image'
                  )
                "
              >
                view
              </button>
            </div>
          </div>
        </div>

        <div class="p-2 d-flex align-items-end paginate my-4">
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              leftClick();
            "
          >
            <ArrowLeftThick />
          </button>
          <input type="text" class="form-control" v-model="page" />
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              rightClick();
            "
          >
            <ArrowRightThick />
          </button>
        </div>

        <div class="images d-none" id="theViewer" v-viewer>
          <img ref="preview" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import ArrowRightThick from "vue-material-design-icons/ArrowRightThick.vue";
import ArrowLeftThick from "vue-material-design-icons/ArrowLeftThick.vue";
import { mapGetters, mapActions } from "vuex";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer, {
  debug: true,
  defaultOptions: {
    zIndex: 9999,
  },
});

export default {
  name: "Abuse",
  components: {
    SideNav,
    Nav,
    ArrowRightThick,
    ArrowLeftThick,
  },
  data() {
    return {
      page: 1,
      limit: process.env.VUE_APP_PAGE_LIMIT,
      env: process.env.VUE_APP_API,
      q: "",
      date: "",
      endDate: "",
    };
  },
  methods: {
    ...mapActions(["fetchAbuses"]),
    scrollTop(el) {
      this.$refs[el].scrollTo(0, 0);
    },
    search() {
      this.page = 1;
      this.fetchAbuses({
        thePage: this.page,
        theLimit: this.limit,
        q: this.q,
        date: this.date,
        endDate: this.endDate,
      });
    },
    searchQ() {
      this.page = 1;
      this.fetchAbuses({
        thePage: this.page,
        theLimit: this.limit,
        q: this.q,
        date: this.date,
        endDate: this.endDate,
        typing: true,
      });
    },
    view(imgSrc, type = null) {
      console.log(imgSrc, type);
      if (type === "pdf") {
        return window.open(imgSrc);
      }
      this.$refs.preview.src = imgSrc;
      const viewer = this.$el.querySelector("#theViewer").$viewer;
      viewer.show();
    },
    rightClick() {
      this.page++;
      this.fetchAbuses({
        thePage: this.page,
        theLimit: this.limit,
        q: this.q,
        date: this.date,
        endDate: this.endDate,
        isLoading: true,
      });
    },
    leftClick() {
      if (this.page !== 1) this.page--;
      this.fetchAbuses({
        thePage: this.page,
        theLimit: this.limit,
        q: this.q,
        date: this.date,
        endDate: this.endDate,
        isLoading: true,
      });
    },
  },
  computed: mapGetters(["allAbuses", "nextAbuse", "previousAbuse"]),
  created() {
    console.log(this.page);
    this.fetchAbuses({
      thePage: this.page,
      theLimit: this.limit,
      q: this.q,
      date: this.date,
      endDate: this.endDate,
    });
  },
};
</script>

<style>
</style>