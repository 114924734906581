<template>
  <div>
    <nav
      class="navbar navbar-light p-0 p-lg-2 px-lg-5 justify-content-between border-bottom"
    >
      <b-button @click="toggleTheNav" variant="white" class="d-md-none">
        <Menu />
      </b-button>
      <a class="navbar-brand ml-4 ml-md-0 p-2" href="#">
        <img src="https://cloud.fexspace.com/logo-small-blue.png" alt="" />
      </a>
      <div class="d-flex align-items-center">
        <div class="mr-4 text-secondary">
          <CogOutline class="mr-4 d-none" />
          <b-button @click="toggleAlerts" variant="white">
            <BellOutline />
            <b-badge variant="danger">{{ ticketNotification.length }}</b-badge>
          </b-button>
          <div class="alerts" :class="{ 'd-none': alertShow }">
            <div
              class="messages dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in show"
              aria-labelledby="messagesDropdown"
            >
              <h6 class="dropdown-header">Notifications</h6>
              <div
                v-if="!ticketNotification.length"
                class="dropdown-item d-flex align-items-center"
              >
                <p>You have no new mails</p>
              </div>
              <router-link
                v-for="ticket in ticketNotification"
                :to="{
                  name: 'ViewTicket',
                  params: { id: ticket.ticket_id },
                  query: { t: new Date().getTime() },
                }"
                :key="ticket.ticket_id"
                class="dropdown-item d-flex align-items-center border-bottom mt-1"
              >
                <div class="font-weight-bold">
                  <div class="text-truncate alert-text">
                    {{ ticket.support_type || "..." }} Ticket
                    {{ ticket.ticket_id }} has a new mail
                  </div>
                  <div class="small text-secondary">
                    {{ ticket.business_email }}
                  </div>
                </div>
              </router-link>
              <div class="d-none dropdown-item text-center small text-primary">
                View all
              </div>
            </div>
          </div>
        </div>
        <div class="d-none d-md-flex">
          <b-avatar class="mr-2"></b-avatar>
          <div class="d-flex flex-column">
            <span class="mr-auto">{{ authUser.name }}</span>
            <span class="mr-auto text-secondary">{{ authUser.email }}</span>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import CogOutline from "vue-material-design-icons/CogOutline.vue";
import BellOutline from "vue-material-design-icons/BellOutline.vue";
import Menu from "vue-material-design-icons/Menu.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Nav",
  components: {
    CogOutline,
    BellOutline,
    Menu,
  },
  data() {
    return {
      alertShow: true,
    };
  },
  methods: {
    ...mapActions(["ticketsWithUnread", "toggleNav"]),
    toggleAlerts() {
      this.alertShow = !this.alertShow;
    },
    toggleTheNav() {
      this.toggleNav();
      this.alertShow = true;
    },
  },
  computed: mapGetters(["isNavOpen", "ticketNotification", "authUser"]),
  created() {
    if (!this.ticketNotification.length) {
      this.ticketsWithUnread();
    }
  },
};
</script>

<style lang="scss" scoped>
.alerts {
  position: relative;

  .alert-text {
    width: 23rem;
  }
}
.messages {
  overflow-x: hidden;
  height: 60vh;
  width: 25rem;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px #ddd;
    box-shadow: inset 0 0 4px #ddd;
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 4px #ddd;
    box-shadow: inset 0 0 4px #ddd;
    background-color: #cfcfcf;
  }
}

@media only screen and (max-width: 600px) {
  .alerts {
    position: inherit;
    .alert-text {
      width: 23rem;
    }
  }
  .messages {
    width: 100%;
  }
}
</style>