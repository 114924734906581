<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        ref="main"
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <header>
          <h2>User Management</h2>
          <p>Manage all users on this platform</p>
          <p class="mb-0"><AccountGroup />{{ userCount }}</p>
        </header>
        <div
          class="d-flex flex-column flex-lg-row query justify-content-between"
        >
          <div class="px-2 d-flex align-items-end">
            <select
              v-model="the_account_type"
              @change="searchAccount"
              class="form-control"
            >
              <option selected value="">Sort by Plan Type</option>
              <option value="0">All</option>
              <option value="1">Free</option>
              <option value="2">Personal Pro</option>
              <option value="3">Business</option>
              <option value="4">Business Plus</option>
            </select>
          </div>
          <div class="px-2 d-flex align-items-end">
            <div>
              <div class="d-flex">
                <label class="m-1 font-weight-bold">SearchBy:</label>
                <div class="form-check m-1">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="email"
                    id="emailCheck"
                    v-model="searchBy"
                    @change="searchByFunc"
                    checked
                  />
                  <label class="form-check-label" for="emailCheck">
                    Email
                  </label>
                </div>
                <div class="form-check m-1">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="name"
                    id="nameCheck"
                    v-model="searchBy"
                    @change="searchByFunc"
                  />
                  <label class="form-check-label" for="nameCheck"> Name </label>
                </div>
              </div>
              <input
                v-debounce:500ms="typing"
                v-model="email"
                type="text"
                class="form-control"
                placeholder="Search..."
              />
            </div>
          </div>

          <div class="px-2 d-flex align-items-end">
            <div class="mr-2">
              <label><small>From:</small></label>
              <input
                v-debounce:700ms="searchByDate"
                v-model="date"
                type="date"
                class="form-control"
              />
            </div>

            <div>
              <label><small>To:</small></label>
              <input
                v-debounce:700ms="searchByDate"
                v-model="endDate"
                type="date"
                class="form-control"
              />
            </div>
          </div>
          <div class="px-2 d-flex align-items-end paginate">
            <button class="btn btn-secondary btn-sm" @click="leftClick">
              <ArrowLeftThick />
            </button>
            <input type="text" class="form-control" v-model="page" />
            <button class="btn btn-secondary btn-sm" @click="rightClick">
              <ArrowRightThick />
            </button>
          </div>
        </div>

        <div
          class="row bg-white p-3 my-2 mt-4 item-row item-head"
          ref="container"
        >
          <div class="col-md-3 d-flex align-items-center">
            <p>Fullname</p>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <p>Email</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Joined</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Subscription</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>Action</p>
          </div>
        </div>

        <div
          v-for="user in allUsers"
          :key="user._id"
          class="row bg-white p-3 my-2 item-row has-cursor"
          @click="view('view' + user._id, $event)"
        >
          <div class="col-md-3 d-flex align-items-center">
            <p>{{ user.name.first + " " + user.name.last }}</p>
          </div>
          <div
            class="col-md-4 d-flex align-items-center d-flex word-break copy-parent"
          >
            <p class="col-10">
              {{ user.email }}
            </p>

            <p
              class="col-2 copy has-cursor"
              v-clipboard="user.email"
              v-clipboard:success="clipboardSuccessHandler"
              v-clipboard:error="clipboardErrorHandler"
              title="Copy Email"
            >
              <ClipboardTextOutline title="Copy Email" />
            </p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ user.createdAt.split("T")[0] }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ user.account_type == 1 ? "personal" : "Buisiness" }}</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <router-link
              v-b-tooltip.hover
              title="View User"
              :ref="'view' + user._id"
              :to="{ name: 'UserPayment', params: { id: user._id } }"
              class="btn btn-light"
              type="button"
              >View</router-link
            >
          </div>
        </div>

        <div class="px-2 d-flex align-items-end paginate my-4">
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              leftClick();
            "
          >
            <ArrowLeftThick />
          </button>
          <input type="text" class="form-control" v-model="page" />
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              rightClick();
            "
          >
            <ArrowRightThick />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";

import ArrowRightThick from "vue-material-design-icons/ArrowRightThick.vue";
import ArrowLeftThick from "vue-material-design-icons/ArrowLeftThick.vue";
import AccountGroup from "vue-material-design-icons/Account.vue";
import ClipboardTextOutline from "vue-material-design-icons/ClipboardMultipleOutline.vue";
import { truncate } from "../utils";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Users",
  components: {
    SideNav,
    Nav,
    ArrowRightThick,
    ArrowLeftThick,
    AccountGroup,
    ClipboardTextOutline,
  },
  data() {
    return {
      the_account_type: "",
      email: "",
      page: 1,
      limit: process.env.VUE_APP_PAGE_LIMIT,
      next: Object,
      previous: Object,
      date: "",
      endDate: "",
      searchBy: "email",
    };
  },
  methods: {
    ...mapActions(["fetchUsers", "searchUsers"]),
    scrollTop(el) {
      this.$refs[el].scrollTo(0, 0);
    },
    view(theref, e) {
      if (!e.target.closest(".copy")) {
        this.$refs[theref][0].$el.click();
      }
    },
    clipboardSuccessHandler() {
      this.$toast.open("copied to clipboard");
    },
    clipboardErrorHandler() {
      this.$toast.open({
        message: "Unable to copy to Clipboard",
        type: "error",
      });
    },
    searchByFunc() {
      if (!this.email) {
        return;
      }
      this.page = 1;
      this.searchUsers({
        the_account_type: +this.the_account_type,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        endDate: this.endDate,
        searchBy: this.searchBy,
        isLoading: true,
      });
    },
    searchByDate() {
      this.page = 1;
      this.searchUsers({
        the_account_type: +this.the_account_type,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        endDate: this.endDate,
        searchBy: this.searchBy,
      });
    },
    typing() {
      this.page = 1;
      this.searchUsers({
        the_account_type: +this.the_account_type,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        endDate: this.endDate,
        typing: true,
        searchBy: this.searchBy,
      });
    },
    searchAccount() {
      this.page = 1;
      this.searchUsers({
        the_account_type: +this.the_account_type,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        endDate: this.endDate,
        isLoading: true,
        searchBy: this.searchBy,
      });
    },
    truncate,
    rightClick() {
      this.page++;
      this.searchUsers({
        the_account_type: +this.the_account_type,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        endDate: this.endDate,
        isLoading: true,
        searchBy: this.searchBy,
      });
    },
    leftClick() {
      if (this.page !== 1) this.page--;
      this.searchUsers({
        the_account_type: +this.the_account_type,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        endDate: this.endDate,
        isLoading: true,
        searchBy: this.searchBy,
      });
    },
  },
  computed: mapGetters(["allUsers", "userCount", "nextUser", "previousUser"]),
  created() {
    this.fetchUsers({
      thePage: this.page,
      theLimit: this.limit,
    });
  },
};
</script>

<style scoped>
label {
  margin-bottom: 0;
}
</style>