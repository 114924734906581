<template>
  <div
    class="col-12 col-md-3 col-xl-2 bd-sidebar p-0 side-nav d-none d-md-block slide-down"
    :class="isNavOpen ? 'd-block' : ''"
  >
    <div>
      <ul class="list-group" @click="toggleNav()">
        <router-link class="list-group-item" to="/home"
          ><Home /> Home</router-link
        >
        <router-link class="list-group-item" to="/abuse"
          ><AlertOctagon /> Abuse</router-link
        >
        <router-link class="list-group-item" to="/companies"
          ><Domain /> Companies</router-link
        >
        <router-link class="list-group-item" to="/feedback"
          ><CommentText /> Feedback</router-link
        >
        <router-link class="list-group-item" to="/users"
          ><Account /> Users</router-link
        >
        <router-link class="list-group-item" to="/support-tickets"
          ><LifeBuoy /> Support Tickets</router-link
        >
        <router-link class="list-group-item" to="/quotes"
          ><CommentQuote /> Quotes</router-link
        >
        <router-link class="list-group-item" to="/faq"
          ><FrequentlyAskedQuestions /> FAQ</router-link
        >
        <router-link class="list-group-item" to="/jobs"
          ><Briefcase /> Jobs</router-link
        >
        <router-link class="list-group-item" to="/payments"
          ><CurrencyUsd /> Payments</router-link
        >
        <router-link class="list-group-item" to="/email"
          ><Email /> Email</router-link
        >
        <!-- <li class="list-group-item"><span><Bell/> Push Notifications</span></li>
        <li class="list-group-item"><span><GoogleAnalytics/> Analytics</span></li>
        <li class="list-group-item"><span><Cog/> Settings</span></li> -->
      </ul>
    </div>
    <hr />
    <div>
      <span @click="logout" class="ml-4 has-cursor logout"
        ><Logout /> Logout</span
      >
    </div>
  </div>
</template>

<script>
import Domain from "vue-material-design-icons/Domain.vue";
// import AccountGroup from 'vue-material-design-icons/AccountGroup.vue';
import Briefcase from "vue-material-design-icons/Briefcase.vue";
import Account from "vue-material-design-icons/Account.vue";
import CommentText from "vue-material-design-icons/CommentText.vue";
// import Bell from 'vue-material-design-icons/Bell.vue';
import Logout from "vue-material-design-icons/Logout.vue";
import LifeBuoy from "vue-material-design-icons/Lifebuoy.vue";
// import GoogleAnalytics from 'vue-material-design-icons/GoogleAnalytics.vue';
import CurrencyUsd from "vue-material-design-icons/CurrencyUsd.vue";
import Home from "vue-material-design-icons/Home.vue";
import CommentQuote from "vue-material-design-icons/CommentQuote.vue";
import AlertOctagon from "vue-material-design-icons/AlertOctagon.vue";
import Email from "vue-material-design-icons/Email.vue";
import FrequentlyAskedQuestions from "vue-material-design-icons/FrequentlyAskedQuestions.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Sidebar",
  components: {
    Domain,
    Briefcase,
    Account,
    CommentText,
    CurrencyUsd,
    Email,
    //Bell,
    Logout,
    LifeBuoy,
    //GoogleAnalytics,
    //Cog,
    Home,
    CommentQuote,
    AlertOctagon,
    FrequentlyAskedQuestions,
  },
  methods: {
    ...mapActions(["toggleNav"]),
    async logout() {
      const loader = this.$loading.show();
      try {
        const response = await fetch(
          process.env.VUE_APP_API + "/api/user/logout"
        );
        if (response.status < 400) {
          localStorage.clear();
          // this.$router.push({ path: "/login" });
          location.href = "/login";
        }
      } catch (e) {
        this.$toast.open({
          message: e,
          type: "error",
        });
      } finally {
        loader.hide();
      }
    },
    isMobile() {},
  },
  computed: mapGetters(["isNavOpen"]),
};
</script>

<style lang="scss" scoped>
/*.side-nav {
  animation: fadeIn ease 0.4s;
  -webkit-animation: fadeIn ease 0.4s;
  -moz-animation: fadeIn ease 0.4s;
  -o-animation: fadeIn ease 0.4s;
  -ms-animation: fadeIn ease 0.4s;
}*/
.ticket div {
  padding: 0;
}
.side-nav > * {
  font-size: 0.9rem;
}
.side-nav .list-group-item {
  border: none;
  align-items: center;
  margin-left: 0.4rem;
  padding: 0.6rem 1.2rem;
  color: inherit;
}
.side-nav .router-link-active {
  border-radius: 0 1.5rem 1.5rem 0;
  background-color: #eaf2f9;
  color: var(--primary);
  padding-left: 0.3rem;
  border-left: 1rem solid #3482c3;
}

.side-nav .list-group-item:hover {
  text-decoration: none;
  border-radius: 0 1.5rem 1.5rem 0;
  background-color: #eaf2f9;
  color: var(--primary);
  padding-left: 0.3rem;
  border-left: 1rem solid #3482c3;
}

.slide-down {
  height: 100vh;
  animation: slideDown ease 0.4s;
  -webkit-animation: slideDown ease 0.4s;
  -moz-animation: slideDown ease 0.4s;
  -o-animation: slideDown ease 0.4s;
  -ms-animation: slideDown ease 0.4s;
}

.logout:hover {
  color: #3482c3;
  text-decoration: underline;
}

@keyframes slideDown {
  from {
    height: 0px;
  }
  to {
    height: 100vh;
  }
}
</style>