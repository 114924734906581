<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        ref="main"
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <div class="row justify-content-between">
          <header>
            <h2>Feedbacks</h2>
            <p>Average Ratings:</p>
            <p>
              <b>Cloud File Mgt.:</b>
              {{ Number(feedbackAverage.cloud_file_mgt).toFixed(1) }}
            </p>
            <p>
              <b>Team Collaboration:</b>
              {{ Number(feedbackAverage.team_collaboration).toFixed(1) }}
            </p>
            <p>
              <b>Seamless Collaboration:</b>
              {{ Number(feedbackAverage.seamless_collaboration).toFixed(1) }}
            </p>
            <p>
              <b>Self Mgt.:</b>
              {{ Number(feedbackAverage.self_managed).toFixed(1) }}
            </p>
          </header>
          <div class="d-flex flex-row">
            <div class="p-2 d-flex align-items-end">
              <div class="px-2 d-flex">
                <div class="mr-2">
                  <label><small>From:</small></label>
                  <input
                    @input="searchByDate"
                    v-model="date"
                    type="date"
                    class="form-control"
                  />
                </div>

                <div>
                  <label><small>To:</small></label>
                  <input
                    @input="searchByDate"
                    v-model="endDate"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="p-2 d-flex align-items-end paginate">
              <button class="btn btn-secondary btn-sm" @click="leftClick">
                <ArrowLeftThick />
              </button>
              <input type="text" class="form-control" v-model="page" />
              <button class="btn btn-secondary btn-sm" @click="rightClick">
                <ArrowRightThick />
              </button>
            </div>
          </div>
        </div>

        <div class="row bg-white p-3 my-2 item-row item-head">
          <div class="col-md-3 d-flex align-items-center">
            <p>Fullname</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>Cloud</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>Team</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>Seamless</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>Self.</p>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <p>Add. info.</p>
          </div>
        </div>

        <div
          v-for="feedback in allFeedbacks"
          :key="feedback._id"
          class="row bg-white p-3 my-2 item-row"
        >
          <div class="col-md-3 d-flex align-items-center">
            <p>
              {{
                feedback.user_id.name.first + " " + feedback.user_id.name.last
              }}
            </p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>{{ feedback.cloud_file_mgt }}</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>{{ feedback.team_collaboration }}</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>{{ feedback.seamless_collaboration }}</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>{{ feedback.self_managed }}</p>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <p>{{ feedback.additional_information }}</p>
          </div>
        </div>

        <div class="p-2 d-flex align-items-end paginate my-4">
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              leftClick();
            "
          >
            <ArrowLeftThick />
          </button>
          <input type="text" class="form-control" v-model="page" />
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              rightClick();
            "
          >
            <ArrowRightThick />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import ArrowRightThick from "vue-material-design-icons/ArrowRightThick.vue";
import ArrowLeftThick from "vue-material-design-icons/ArrowLeftThick.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Feedback",
  components: {
    SideNav,
    Nav,
    ArrowRightThick,
    ArrowLeftThick,
  },
  data() {
    return {
      page: 1,
      limit: process.env.VUE_APP_PAGE_LIMIT,
      next: Object,
      previous: Object,
      date: "",
      endDate: "",
    };
  },
  methods: {
    ...mapActions(["fetchFeedbacks"]),

    scrollTop(el) {
      this.$refs[el].scrollTo(0, 0);
    },
    searchByDate() {
      this.page = 1;
      this.fetchFeedbacks({
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        endDate: this.endDate,
      });
    },
    rightClick() {
      this.page++;
      this.fetchFeedbacks({
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        endDate: this.endDate,
      });
    },
    leftClick() {
      if (this.page !== 1) this.page--;
      this.fetchFeedbacks({
        thePage: this.page,
        theLimit: this.limit,
        date: this.date,
        endDate: this.endDate,
      });
    },
  },
  computed: mapGetters([
    "allFeedbacks",
    "nextFeedback",
    "previousFeedback",
    "feedbackAverage",
  ]),
  created() {
    this.fetchFeedbacks({
      thePage: this.page,
      theLimit: this.limit,
      date: this.date,
      endDate: this.endDate,
    });
  },
};
</script>

<style scoped>
</style>