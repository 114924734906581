<template>
  <div>
    <div
    v-for="dept in allDepts"
    :key="dept._id"
    class="row bg-white p-2 my-2 item-row item-row border border-bottom w-50">
      <div class="col-md-8 d-flex align-items-center">
        <p>{{ dept.name }}</p>
      </div>
      <div class="col-md-2">
        <button class="btn btn-sm btn-outline-danger" @click="deleteDept(dept._id)">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ViewDepts',
   methods: {
    ...mapActions(['fetchDepts', 'delDept']),
    deleteDept(id) {
      this.delDept({
        id
      })
    }
   },
  computed: mapGetters(['allDepts']),
  created() {
    this.fetchDepts();
  }
}
</script>

<style>

</style>