<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <GoBack />
        <div>
          <h2>{{ company.name }}</h2>
          <p>{{ company.address }}, {{ company.country }}</p>
          <p class="mb-0"><Account /> {{ companyUserCount }}</p>
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="p-2 d-flex align-items-end paginate">
              <button class="btn btn-secondary btn-sm" @click="leftClick">
                <ArrowLeftThick />
              </button>
              <input type="text" class="form-control" v-model="page" />
              <button class="btn btn-secondary btn-sm" @click="rightClick">
                <ArrowRightThick />
              </button>
            </div>
            <div class="px-2 d-flex align-items-end">
              <div>
                <div class="d-flex">
                  <label class="m-1 font-weight-bold">SearchBy:</label>
                  <div class="form-check m-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="email"
                      id="emailCheck"
                      v-model="searchBy"
                      @change="searchByFunc"
                      checked
                    />
                    <label class="form-check-label" for="emailCheck">
                      Email
                    </label>
                  </div>
                  <div class="form-check m-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="name"
                      id="nameCheck"
                      v-model="searchBy"
                      @change="searchByFunc"
                    />
                    <label class="form-check-label" for="nameCheck">
                      Name
                    </label>
                  </div>
                </div>
                <input
                  v-debounce:500ms="typing"
                  v-model="q"
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                />
              </div>
            </div>
          </div>

          <div class="p-2">
            <router-link
              class="btn btn-primary"
              :to="{
                name: 'ViewCompanyStats',
                params: { id: company._id, name: company.name },
              }"
            >
              Company Stats
            </router-link>
          </div>
        </div>

        <div class="row bg-white p-2 my-2 item-row item-head">
          <div class="col-md-3 d-flex align-items-center">
            <p>Fullname</p>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <p>Email</p>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <p>Joined</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Subscription</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>Action</p>
          </div>
        </div>

        <div
          v-for="user in companyUsers"
          :key="user._id"
          class="row bg-white p-2 my-2 item-row"
        >
          <div class="col-md-3 d-flex align-items-center">
            <p>{{ user.name.first + " " + user.name.last }}</p>
          </div>
          <div class="col-md-3 d-flex align-items-center word-break">
            <p>{{ user.email }}</p>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <p>{{ user.createdAt.split("T")[0] }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ user.account_type == 1 ? "personal" : "Buisiness" }}</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <router-link
              :to="{ name: 'UserPayment', params: { id: user._id } }"
              class="btn btn-light"
              type="button"
              >View</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import GoBack from "../components/GoBack";
import ArrowRightThick from "vue-material-design-icons/ArrowRightThick.vue";
import ArrowLeftThick from "vue-material-design-icons/ArrowLeftThick.vue";
import Account from "vue-material-design-icons/Account.vue";
import { mapGetters, mapActions } from "vuex";
import { truncate } from "../utils";

export default {
  name: "ViewCompany",
  components: {
    SideNav,
    Nav,
    GoBack,
    ArrowRightThick,
    ArrowLeftThick,
    Account,
  },
  props: {
    id: String,
  },
  data() {
    return {
      page: 1,
      limit: process.env.VUE_APP_PAGE_LIMIT,
      searchBy: "email",
      q: "",
    };
  },
  methods: {
    ...mapActions(["getCompany"]),
    truncate,
    searchByFunc() {
      if (!this.email) {
        return;
      }
      this.page = 1;
      this.getCompany({
        id: this.id,
        thePage: this.page,
        theLimit: this.limit,
        q: this.q,
        isLoading: true,
        searchBy: this.searchBy,
      });
    },
    typing() {
      this.page = 1;
      this.getCompany({
        id: this.id,
        thePage: this.page,
        theLimit: this.limit,
        q: this.q,
        isLoading: false,
        searchBy: this.searchBy,
      });
    },
    rightClick() {
      this.page++;
      this.getCompany({
        id: this.id,
        thePage: this.page,
        theLimit: this.limit,
        q: this.q,
        isLoading: true,
        searchBy: this.searchBy,
      });
    },
    leftClick() {
      if (this.page !== 1) this.page--;
      this.getCompany({
        id: this.id,
        thePage: this.page,
        theLimit: this.limit,
        q: this.q,
        isLoading: true,
        searchBy: this.searchBy,
      });
    },
  },
  computed: mapGetters(["companyUsers", "company", "companyUserCount"]),
  created() {
    this.getCompany({
      id: this.id,
      thePage: this.page,
      theLimit: this.limit,
      isLoading: true,
      searchBy: this.searchBy,
      q: this.q,
    });
  },
};
</script>

<style scoped>
</style>