<template>
  <div>
    <button @click="goBack()" class="btn btn-outline-primary">
      <ChevronLeft /> Back
    </button>
    <hr />
  </div>
</template>

<script>
import ChevronLeft from "vue-material-design-icons/ChevronLeft.vue";
export default {
  name: "GoBack",
  components: { ChevronLeft },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>