<template>
  <div>
    <b-button v-b-modal.add-job>Add Job</b-button>
    <b-modal
      id="add-job"
      size="md"
      title="Add Job"
      :hide-footer="true"
      no-close-on-backdrop
    >
      <form @submit.prevent="submit">
        <!-- <small :style="{ color: 'red' }">{{}}</small> -->
        <div class="form-group">
          <label>Job Title</label>
          <input
            required
            v-model="form.jobTitle"
            type="text"
            class="form-control bg-light"
          />
        </div>

        <div class="form-group">
          <label>Department</label>
          <input
            required
            v-model="form.department"
            type="text"
            class="form-control bg-light"
          />
        </div>

        <div class="form-group">
          <label>Department</label>
          <select
            required
            v-model="form.department"
            class="custom-select bg-light"
          >
            <option disabled selected value="">Select Department</option>
            <option v-for="dept in allDepts" :key="dept._id" :value="dept.name">
              {{ dept.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label>Location</label>
          <input
            required
            v-model="form.location"
            type="text"
            class="form-control bg-light"
          />
        </div>

        <div class="form-group">
          <label>Vacancies</label>
          <input
            required
            v-model="form.vacancies"
            type="Number"
            class="form-control bg-light"
          />
        </div>

        <div class="form-group">
          <label>Experience</label>
          <input
            required
            v-model="form.experience"
            type="Text"
            class="form-control bg-light"
          />
        </div>

        <div class="form-group">
          <label>Job Type</label>
          <input
            required
            v-model="form.jobType"
            type="text"
            class="form-control bg-light"
          />
        </div>

        <div class="form-group">
          <label>Status</label>
          <input
            required
            v-model="form.status"
            type="Number"
            class="form-control bg-light"
          />
        </div>

        <div class="row">
          <div class="form-group col-6">
            <label>Salary From</label>
            <input
              required
              v-model="form.salaryFrom"
              type="text"
              class="form-control bg-light"
            />
          </div>

          <div class="form-group col-6">
            <label>Salary To</label>
            <input
              required
              v-model="form.salaryTo"
              type="text"
              class="form-control bg-light"
            />
          </div>

          <div class="form-group col-6">
            <label>startDate</label>
            <input
              required
              v-model="form.startDate"
              type="date"
              class="form-control bg-light"
            />
          </div>

          <div class="form-group col-6">
            <label>expiredDate</label>
            <input
              required
              v-model="form.expiredDate"
              type="date"
              class="form-control bg-light"
            />
          </div>
        </div>

        <div class="form-group">
          <label>Description</label>
          <textarea
            required
            v-model="form.description"
            type="text"
            class="form-control bg-light"
            rows="3"
          >
          </textarea>
        </div>

        <button type="submit" ref="submit" class="btn btn-primary float-right">
          Save
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
// import { fetchApi } from '../utils';
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AddJob",
  data() {
    return {
      form: {
        jobTitle: "",
        department: "",
        location: "",
        vacancies: "",
        experience: "",
        salaryFrom: "",
        salaryTo: "",
        jobType: "",
        status: "",
        startDate: "",
        expiredDate: "",
        description: "",
      },
    };
  },
  computed: mapGetters(["allDepts"]),
  methods: {
    ...mapActions(["addJobs"]),
    async submit() {
      const status = await this.addJobs({
        jobTitle: this.form.jobTitle,
        department: this.form.department,
        location: this.form.location,
        vacancies: this.form.vacancies,
        experience: this.form.experience,
        salaryFrom: this.form.salaryFrom,
        salaryTo: this.form.salaryTo,
        jobType: this.form.jobType,
        status: this.form.status,
        startDate: this.form.startDate,
        expiredDate: this.form.expiredDate,
        description: this.form.description,
      });
      if (status) {
        let x;
        for (x in this.form) {
          this.form[x] = "";
        }
        this.$bvModal.hide("add-job");
      }
    },
  },
};
</script>

<style>
</style>