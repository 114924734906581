<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label><b>From:</b></label>
          <select class="custom-select" v-model="sender" ref="sender">
            <option value="" disabled selected>Choose Sender</option>
            <option value="care@fexspace.com">care@fexspace.com</option>
            <option value="support@fexspace.com">support@fexspace.com</option>
            <option value="sales@fexspace.com">sales@fexspace.com</option>
          </select>
        </div>

        <div class="form-group">
          <label><b>Subject:</b></label>
          <textarea
            ref="subject"
            required
            v-model="subject"
            type="text"
            class="form-control"
          ></textarea>
        </div>
      </div>

      <div class="form-group col-md-4">
        <label><b>Receipient:</b></label>
        <p><Account /> {{ pUser.name.first + " " + pUser.name.last }}</p>
        <p>
          <Email /> <span class="word-break">{{ pUser.email }}</span>
        </p>
      </div>
    </div>

    <div class="example bg-white border-bottom">
      <quill-editor class="editor" ref="myQuillEditor" v-model="content" />
    </div>

    <div>
      <label for="attachments" class="btn btn-light m-2"
        ><Paperclip /> Add attachment
        <b-badge variant="primary">{{ fileCount }}</b-badge></label
      >
      <input
        type="file"
        id="attachments"
        name="attachments"
        accept=""
        multiple
        class="d-none"
        @change="countFile"
      />
      <button class="btn btn-primary m-2" @click="sendMail()" ref="sendMail">
        {{ btntext }}
      </button>
    </div>

    <hr v-if="files.length" />
    <div
      v-for="(file, index) in files"
      :key="index"
      class="d-flex justify-content-between mb-1 col-md-6"
    >
      <div>{{ file.name }}</div>
      <button
        class="btn btn-sm btn-outline-danger"
        @click="removeAttachment(index)"
      >
        Remove
      </button>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import Paperclip from "vue-material-design-icons/Paperclip.vue";
import Account from "vue-material-design-icons/Account.vue";
import Email from "vue-material-design-icons/Email.vue";
import { fetchAPI } from "../utils";

export default {
  name: "SendUserMail",
  components: {
    quillEditor,
    Paperclip,
    Account,
    Email,
  },
  props: {
    pUser: Object,
  },
  data() {
    return {
      btntext: "Send Mail",
      subject: "",
      content: "",
      fileCount: "",
      receipient: "",
      sender: "",
      files: [],
    };
  },
  methods: {
    countFile(e) {
      console.log("count", e);
      const files = [];
      e.target.files.forEach((file) => {
        files.push(file);
      });
      this.files = [...this.files, ...files];
      this.fileCount = this.files.length;
    },
    removeAttachment(index) {
      const files = Array.from(this.files).filter((x, i) => i !== index);
      this.files = files;
      this.fileCount = this.files.length;
    },
    async sendMail() {
      try {
        if (this.sender === "") {
          const sender = this.$refs.sender;
          sender.focus();
          sender.parentElement.scrollIntoView();
          return;
        }
        if (this.subject === "") {
          const subjectEl = this.$refs.subject;
          subjectEl.focus();
          subjectEl.parentElement.scrollIntoView();
          return;
        }
        if (this.content === "") {
          return;
        }

        const formData = new FormData();
        if (this.files.length) {
          this.files.forEach((file) => {
            formData.append("attachments", file, file.name);
          });
        }

        this.btntext = "Sending...";
        this.$refs.sendMail.disabled = true;
        formData.append("reciepient", this.pUser.email);
        formData.append("sender", this.sender);
        formData.append("subject", this.subject);
        formData.append("content", this.content);
        formData.append(
          "name",
          `${this.pUser.name.first} ${this.pUser.name.last}`
        );

        const response = await fetchAPI(
          process.env.VUE_APP_API + `/api/mail/single-mail`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.status < 400) {
          this.resetForm();
          this.$toast.open("Mail Sent");
        } else {
          this.$toast.open({
            message: "Server Unable to send mail",
            type: "error",
          });
        }
      } catch (e) {
        console.log(e);
        this.$toast.open({
          message: "Error: sending mail",
          type: "error",
        });
      } finally {
        this.btntext = "Send Mail";
        this.$refs.sendMail.disabled = false;
      }
    },
    resetForm() {
      this.subject = "";
      this.content = "";
      this.receipient = "";
      this.files = [];
      // close modal
      this.$bvModal.hide("send-mail");
    },
  },
};
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 25rem;
    overflow: hidden;
  }
}

.email-html {
  white-space: break-spaces;
}
</style>