<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        ref="main"
        class="main col-12 col-md-9 col-xl-10 py-md-3 p-md-5 bd-content bg-light rounded overflow-auto"
      >
        <header>
          <h2>Support Tickets</h2>
          <p class="mb-0">Respond to customer complaints</p>
        </header>
        <div>
          <div
            class="d-flex flex-column flex-lg-row query justify-content-between"
          >
            <div class="px-1 mb-2 d-flex align-items-end">
              <select
                class="custom-select"
                @change="select"
                v-model="support_type"
              >
                <option disabled selected value="">Sort by Support Type</option>
                <option value="">All</option>
                <option value="enquiry">Enquiry</option>
                <option value="sales">Sales</option>
                <option value="Tech S">Tech Support</option>
                <option value="abuse">Abuse</option>
                <option value="Support">Support</option>
                <option value="Quote">Quote</option>
              </select>
            </div>
            <div class="p-2 d-flex align-items-end">
              <div>
                <div class="d-flex">
                  <div class="form-check m-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="ticket"
                      id="ticketCheck"
                      v-model="searchBy"
                      @change="searchByFunc"
                      checked
                    />
                    <label class="form-check-label" for="ticketCheck">
                      Ticket ID
                    </label>
                  </div>
                  <div class="form-check m-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="email"
                      id="emailCheck"
                      v-model="searchBy"
                      @change="searchByFunc"
                      checked
                    />
                    <label class="form-check-label" for="emailCheck">
                      Email
                    </label>
                  </div>
                  <div class="form-check m-1">
                    <input
                      class="form-check-input"
                      type="radio"
                      value="name"
                      id="nameCheck"
                      v-model="searchBy"
                      @change="searchByFunc"
                    />
                    <label class="form-check-label" for="nameCheck">
                      Name
                    </label>
                  </div>
                </div>
                <input
                  v-debounce:600ms="typing"
                  v-model="email"
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                />
              </div>
            </div>
            <div class="p-2 d-flex align-items-end">
              <div class="px-2 d-flex">
                <div class="mr-2">
                  <label><small>From:</small></label>
                  <input
                    v-debounce:700ms="search"
                    v-model="date"
                    type="date"
                    class="form-control"
                  />
                </div>

                <div>
                  <label><small>To:</small></label>
                  <input
                    v-debounce:700ms="search"
                    v-model="endDate"
                    type="date"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="p-2 d-flex align-items-end paginate">
              <button class="btn btn-secondary btn-sm" @click="leftClick">
                <ArrowLeftThick />
              </button>
              <input type="text" class="form-control" v-model="page" />
              <button class="btn btn-secondary btn-sm" @click="rightClick">
                <ArrowRightThick />
              </button>
            </div>
          </div>
        </div>

        <div class="row bg-white p-3 my-2 item-row item-head">
          <div class="col-md-2 d-flex align-items-center">
            <p>TicketID</p>
          </div>
          <div class="col-md-3 d-flex align-items-center">
            <p>Created By</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Date Created</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Support Type</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>Status</p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <p>Action</p>
          </div>
        </div>

        <div
          v-for="ticket in allTickets"
          :key="ticket._id"
          @click="view('view' + ticket._id)"
          class="row bg-white p-3 my-2 item-row has-cursor"
        >
          <div
            class="col-md-2 d-flex align-items-center"
            v-b-tooltip.hover
            :title="ticket.ticket_id"
          >
            <p>{{ ticket.ticket_id }}</p>
          </div>
          <div class="col-md-3 d-flex align-items-center word-break">
            <p>
              <b-avatar
                src="https://placekitten.com/300/300"
                class="d-none"
              ></b-avatar>
              {{ ticket.fullname }}, {{ ticket.business_email }}
            </p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ ticket.createdAt.split("T")[0] }}</p>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <p>{{ ticket.support_type }}</p>
          </div>
          <div class="col-md-2 text-success d-flex align-items-center">
            <p
              :class="[
                ticket.status === 'resolved' ? 'text-success' : 'text-warning',
              ]"
            >
              {{ ticket.status }}
            </p>
          </div>
          <div class="col-md-1 d-flex align-items-center">
            <div class="dropdown d-none">
              <button
                class="btn btn-light dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                View
              </button>
            </div>
            <router-link
              v-b-tooltip.hover
              title="View Ticket"
              :ref="'view' + ticket._id"
              class="btn btn-secondary btn-sm"
              :to="{ name: 'ViewTicket', params: { id: ticket.ticket_id } }"
              ><ArrowRightThick
            /></router-link>
          </div>
        </div>

        <div class="p-2 d-flex align-items-end paginate my-4">
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              leftClick();
            "
          >
            <ArrowLeftThick />
          </button>
          <input type="text" class="form-control" v-model="page" />
          <button
            class="btn btn-secondary btn-sm"
            @click="
              scrollTop('main');
              rightClick();
            "
          >
            <ArrowRightThick />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import ArrowRightThick from "vue-material-design-icons/ArrowRightThick.vue";
import ArrowLeftThick from "vue-material-design-icons/ArrowLeftThick.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SupportTickets",
  components: {
    SideNav,
    Nav,
    ArrowRightThick,
    ArrowLeftThick,
  },
  data() {
    return {
      email: "",
      date: "",
      endDate: "",
      page: 1,
      limit: process.env.VUE_APP_PAGE_LIMIT,
      next: Object,
      previous: Object,
      support_type: "",
      searchBy: "email",
    };
  },
  methods: {
    ...mapActions([
      "fetchTickets",
      "searchTickets",
      "nextTicket",
      "previousTicket",
    ]),
    scrollTop(el) {
      this.$refs[el].scrollTo(0, 0);
    },
    view(theref) {
      this.$refs[theref][0].$el.click();
    },
    searchByFunc() {
      if (!this.email) {
        return;
      }
      this.page = 1;
      this.searchTickets({
        date: this.date,
        endDate: this.endDate,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        support_type: this.support_type,
        searchBy: this.searchBy,
        typing: true,
      });
    },
    select() {
      this.page = 1;
      this.searchTickets({
        date: this.date,
        endDate: this.endDate,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        support_type: this.support_type,
        searchBy: this.searchBy,
      });
    },
    typing() {
      this.page = 1;
      this.searchTickets({
        date: this.date,
        endDate: this.endDate,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        support_type: this.support_type,
        typing: true,
        searchBy: this.searchBy,
      });
    },
    search() {
      this.page = 1;
      this.searchTickets({
        date: this.date,
        endDate: this.endDate,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        support_type: this.support_type,
        searchBy: this.searchBy,
      });
    },
    rightClick() {
      this.page++;
      this.searchTickets({
        date: this.date,
        endDate: this.endDate,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        support_type: this.support_type,
        searchBy: this.searchBy,
      });
    },
    leftClick() {
      if (this.page !== 1) this.page--;
      this.searchTickets({
        date: this.date,
        endDate: this.endDate,
        email: this.email,
        thePage: this.page,
        theLimit: this.limit,
        support_type: this.support_type,
        searchBy: this.searchBy,
      });
    },
  },
  computed: mapGetters(["allTickets"]),
  created() {
    this.fetchTickets({
      date: this.date,
      endDate: this.endDate,
      thePage: this.page,
      theLimit: this.limit,
    });
  },
};
</script>

<style scoped>
</style>