<template>
  <div>
    <Nav />
    <div class="row flex-xl-nowrap w-100">
      <SideNav />
      <div
        class="main col-12 col-md-9 col-xl-10 py-md-1 p-md-5 bd-content bg-light rounded overflow-auto"
        ref="main"
      >
        <GoBack />
        <div class="bg-white my-2 p-2 rounded">
          <h2>View Ticket</h2>
          <p>
            {{ singleTicket.fullname }}, {{ singleTicket.support_type }},
            {{ singleTicket.business_email }}, {{ singleTicket.phone }}
          </p>
          <div>
            <p><b>message</b></p>
            <p class="email-html" v-html="singleTicket.message"></p>
          </div>

          <div v-if="singleTicket.attachments.length">
            <hr />
            <div>
              <p><Attachment /> Attachments</p>
              <div v-for="att in singleTicket.attachments" :key="att._id">
                <p>{{ att.name }}</p>
                <a
                  class="btn btn-sm btn-secondary"
                  :href="`${env}/api/download/ticket-file${att.path}/${att.name}`"
                  :download="att.name"
                  >Download</a
                >
                <!--<img :src="`${env}/api/download/ticket-file${att.path}/${att.name}`"/> -->
                <button
                  v-if="att.type.includes('image')"
                  class="btn btn-sm btn-light ml-2"
                  @click="
                    view(
                      `${env}/api/download/ticket-file${att.path}/${att.name}`,
                      'image'
                    )
                  "
                >
                  view
                </button>
                <button
                  v-if="att.type.includes('pdf')"
                  class="btn btn-sm btn-light ml-2"
                  @click="view(`${env}/pdf${att.path}`, 'pdf')"
                >
                  view PDF
                </button>
              </div>
            </div>
          </div>
          <p>
            status: <span>{{ singleTicket.status }}</span>
          </p>
          <div>
            <input
              type="checkbox"
              name="resolve"
              id="resolve"
              class="mr-2"
              v-on:change="changeStatus"
              v-bind:checked="singleTicket.status === 'resolved'"
            />
            <label for="resolve">resolve</label>
          </div>
        </div>

        <div class="example bg-white border-bottom">
          <quill-editor class="editor" ref="myQuillEditor" v-model="content" />
        </div>
        <div>
          <label for="attachments" class="btn btn-light m-2"
            ><Paperclip /> Add attachment
            <b-badge variant="primary">{{ fileCount }}</b-badge></label
          >
          <input
            type="file"
            id="attachments"
            name="attachments"
            accept=""
            multiple
            class="d-none"
            @change="countFile"
          />
          <button
            class="btn btn-primary m-2"
            @click="sendReply()"
            ref="sendMail"
          >
            {{ btntext }}
          </button>
        </div>

        <hr v-if="files.length" />
        <div
          v-for="(file, index) in files"
          :key="index"
          class="d-flex justify-content-between mb-1 col-md-6"
        >
          <div>{{ file.name }}</div>
          <button
            class="btn btn-sm btn-outline-danger"
            @click="removeAttachment(index)"
          >
            Remove
          </button>
        </div>

        <b-card
          class="my-4"
          header-text-variant="primary"
          header-bg-variant="transparent"
          v-for="message in singleTicket.email_messages"
          :key="message._id"
        >
          <b-card-text>
            <div class="font-weight-bold float-right">
              <p v-if="message.from.includes('Fexspace')">Staff</p>
              <p v-else>User</p>
            </div>
            <p class="text-primary">{{ message.from }}</p>
            <div v-html="message.html"></div>
            <div v-if="message.attachments.length">
              <hr />
              <div>
                <p><Attachment /> Attachments</p>
                <div v-for="att in message.attachments" :key="att._id">
                  <p>{{ att.name }}</p>
                  <a
                    class="btn btn-sm btn-secondary"
                    :href="`${env}/api/download/ticket-file${att.path}/${att.name}`"
                    :download="att.name"
                    >Download</a
                  >
                  <!--<img :src="`${env}/api/download/ticket-file${att.path}/${att.name}`"/> -->
                  <button
                    v-if="att.type.includes('image')"
                    class="btn btn-sm btn-light ml-2"
                    @click="
                      view(
                        `${env}/api/download/ticket-file${att.path}/${att.name}`,
                        'image'
                      )
                    "
                  >
                    view
                  </button>
                  <button
                    v-if="att.type.includes('pdf')"
                    class="btn btn-sm btn-light ml-2"
                    @click="view(`${env}/pdf${att.path}`, 'pdf')"
                  >
                    view PDF
                  </button>
                </div>
              </div>
            </div>

            <p class="text-secondary">
              <small>{{ message.date }}</small>
            </p>
          </b-card-text>
        </b-card>

        <div class="images d-none" id="theViewer" v-viewer>
          <img ref="preview" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import SideNav from "../components/SideNav.vue";
import Nav from "../components/Nav.vue";
import GoBack from "../components/GoBack";
import Paperclip from "vue-material-design-icons/Paperclip.vue";
import Attachment from "vue-material-design-icons/Attachment.vue";
import { mapGetters, mapActions } from "vuex";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer, {
  debug: true,
  defaultOptions: {
    zIndex: 9999,
  },
});

export default {
  name: "ViewTicket",
  components: {
    SideNav,
    Nav,
    quillEditor,
    Paperclip,
    Attachment,
    GoBack,
  },
  props: {
    id: String,
  },
  data() {
    return {
      form: {
        text: "",
      },
      btntext: "Send Mail",
      content: "",
      fileCount: "",
      files: [],
      env: process.env.VUE_APP_API,
    };
  },
  methods: {
    ...mapActions(["replyMail", "getSingleTicket", "changeTicketStatus"]),
    view(imgSrc, type = null) {
      console.log(imgSrc, type);
      if (type === "pdf") {
        return window.open(imgSrc);
      }
      this.$refs.preview.src = imgSrc;
      const viewer = this.$el.querySelector("#theViewer").$viewer;
      viewer.show();
    },
    removeAttachment(index) {
      const files = Array.from(this.files).filter((x, i) => i !== index);
      this.files = files;
      this.fileCount = this.files.length;
    },
    async sendReply() {
      try {
        let formData = new FormData();
        if (this.files.length) {
          this.files.forEach((file) => {
            formData.append("attachments", file, file.name);
          });
        }

        this.btntext = "Sending...";
        this.$refs.sendMail.disabled = true;
        formData.append("text", this.content);

        const { success } = await this.replyMail({
          id: this.singleTicket._id,
          formData,
        });
        if (success) {
          this.content = "";
          this.fileCount = "";
          this.files = [];
          // scroll to bottom
          const el = this.$refs.main;
          el.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      } catch (e) {
        this.$toast.open({
          message: "Unable to Send Reply",
          type: "error",
        });
      } finally {
        this.btntext = "Send Mail";
        this.$refs.sendMail.disabled = false;
      }
    },
    countFile(e) {
      console.log("count", e);
      const files = [];
      e.target.files.forEach((file) => {
        files.push(file);
      });
      this.files = [...this.files, ...files];
      this.fileCount = this.files.length;
    },
    changeStatus(e) {
      const status = e.target.checked ? "resolved" : "pending";
      this.changeTicketStatus({
        status,
        ticket_id: this.singleTicket.ticket_id,
      });
    },
  },
  computed: mapGetters(["singleTicket"]),
  created() {
    this.getSingleTicket(this.id);
    //if (!this.ticket) window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
  },
};
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 25rem;
    overflow: hidden;
  }
}

.email-html {
  white-space: break-spaces;
}
</style>